export const colors = {
    BLACK: '#2D2424',
    BEIGE: '#E0C097',
    OFFWHITE: '#FFFBE9',
    ORANGE: '#B85C38',
    WHITE: '#FFFFFF',
    BASE: '#121212',
}

export const darkMode = {
    BASE: '#121212',
    ONE: 'rgba(255,255,255,0.05)',
    TWO: 'rgba(255,255,255,0.08)',
    THREE: 'rgba(255,255,255,0.11)',
    FOUR: 'rgba(255,255,255,0.14)'
}