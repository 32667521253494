import { useRef } from "react";
import { TEXT } from "../../lib/text";
import styled from "styled-components";
import { useOnScreen } from "../../hooks/useOnScreen";
import { colors } from "../../lib/colors";
import { MOBILE_BREAKPOINT } from "../../lib/breakpoints";

const Heading = styled.h1({
  fontWeight: "normal",
  fontSize: "2rem",
  margin: 0,
  padding: "0px 10px 0px 10px",
  [MOBILE_BREAKPOINT]: {
    fontSize: "3rem",
  },
});
const TextBlock = styled.h3({
  fontWeight: "normal",
  fontSize: "1.2rem",
  padding: "0px 30px 0px 30px",
  "@media(prefers-reduced-motion)": {
    opacity: "0",
    transition: "all 3s",
  },
  margin: 0,
  [MOBILE_BREAKPOINT]: {
    maxWidth: "800px",
    fontSize: "2rem",
  },
});

const TwoPanel = styled.div({
  display: "flex",
  minHeight: "100vh",
  justifyContent: "center",
  flexDirection: "column",
  backgroundColor: colors.BASE,
  color: "#ffffff",
});

const TopPanel = styled.div({
  margin: "0px 0px 40px 0px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  alignContent: "center",
  flexDirection: "column",
  [MOBILE_BREAKPOINT]: {
    display: "flex",
    alignItems: "center",
  },
});
const BottomPanel = styled.div({
  width: "100%",
});

const RightPanelImage = styled.img({
  objectFit: "cover",
  width: "100%",
  height: "35vh",
});
export function AboutPage(): JSX.Element {
  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);

  return (
    <TwoPanel id="about-page">
      <TopPanel>
        <Heading>Our Commitment</Heading>
        <TextBlock ref={elementRef} style={{ opacity: isOnScreen ? 1 : 0 }}>
          {TEXT.OUR_FIRM}
        </TextBlock>
      </TopPanel>
      <BottomPanel>
        <RightPanelImage
          src="\giammarco-zeH-ljawHtg-unsplash.jpg"
          alt="office"
        />
      </BottomPanel>
    </TwoPanel>
  );
}
