import styled from "styled-components";
import ReviewCard from "./ReviewCard";
import { reviews } from "../../client/data";
import SwipeableViews from "react-swipeable-views";
const Section = styled.div({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
});

const Title = styled.h1({
  fontSize: "3rem",
  fontWeight: "normal",
  width: "100%",
  marginBottom: 0,
});

export default function ReviewsPage(): JSX.Element {
  return (
    <Section>
      <Title>Client Testimonials</Title>
      <div style={{ height: "60vh" }}>
        <SwipeableViews
          enableMouseEvents
          containerStyle={{ height: "100%" }}
          style={{ height: "100%" }}
          slideStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {[0, 1, 2].map((ind) => {
            return <ReviewCard review={reviews[ind]} key={ind} />;
          })}
        </SwipeableViews>
      </div>
    </Section>
  );
}
