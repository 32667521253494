import React from "react";
import styled from "styled-components";
import { colors } from "../../../lib/colors";
import { TeamMember } from "../TeamPage";
import { MOBILE_BREAKPOINT } from "../../../lib/breakpoints";

const Card = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "320px",
  height: "250px",
  backgroundColor: "#F2F1F1",
  boxShadow: "0px 10px 14px rgba(0, 0, 0, 0.25)",
  [MOBILE_BREAKPOINT]: {
    width: "850px",
    height: "500px",
    gap: "20px",
  },
});

const InnerBorder = styled.div({
  height: "90%",
  width: "95%",
  border: "2px solid #000000",
});

const Grid = styled.div({
  height: "100%",
  display: "grid",
  gridTemplateColumns: "3fr 2fr",
  placeItems: "center",
});

const ProfilePic = styled.img({
  width: "70%",
  maxWidth: "150px",
  margin: "15px",
  border: "3px solid black",
  [MOBILE_BREAKPOINT]: {
    display: "block",
    width: "100%",
    maxWidth: "200px",
  },
});

const TextContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginLeft: "20px",
  width: "100%",
  [MOBILE_BREAKPOINT]: {
    rowGap: "10px",
    marginLeft: "40px",
  },
});

const ScalesOfJusticeIcon = styled.img({
  width: "20px",
  [MOBILE_BREAKPOINT]: {
    width: "40px",
  },
});

const NameTag = styled.h1({
  color: colors.BLACK,
  fontWeight: "bold",
  fontSize: "1rem",
  margin: "10px 0 0 0",
  [MOBILE_BREAKPOINT]: {
    fontSize: "1.8rem",
  },
});

const Title = styled.h1({
  color: colors.BLACK,
  opacity: 1,
  fontWeight: "normal",
  fontSize: "1rem",
  margin: "0 0 5px 0",
  whiteSpace: "pre-line",
});

const Divider = styled.hr({
  width: "20%",
  height: "4px",
  backgroundColor: "black",
  textAlign: "left",
  marginLeft: 0,
});

const Email = styled.a({
  color: colors.BLACK,
  textDecoration: "none",
  fontWeight: "normal",
  fontSize: "1rem",
  margin: "0 0 5px 0",
  whiteSpace: "pre-line",
});

const Phone = styled.a({
  color: colors.BLACK,
  fontWeight: "bold",
  fontSize: "1rem",
  margin: "0 0 5px 0",
  whiteSpace: "pre-line",
});

const GridItem = styled.div({
  display: "flex",
  flexDirection: "column",
});

type TeamCardProps = {
  teamMember: TeamMember;
};
export function TeamCard(props: TeamCardProps): JSX.Element {
  const { teamMember } = props;
  return (
    <Card>
      <InnerBorder>
        <Grid>
          <TextContainer>
            <ScalesOfJusticeIcon
              src={"/scales-of-justice.png"}
              alt="scales of justice"
            />
            <NameTag>{teamMember.name}</NameTag>
            <Title>{teamMember.title}</Title>
            <Divider />
            <GridItem>
              <Phone>{`Tel: ${teamMember.phone}`}</Phone>
              <Email href={`mailto:${teamMember.email}`}>
                {`Email: ${teamMember.email}`}
              </Email>
            </GridItem>
          </TextContainer>
          <ProfilePic src={teamMember.img} alt={teamMember.description} />
        </Grid>
      </InnerBorder>
    </Card>
  );
}

// TODO <a href="https://www.flaticon.com/free-icons/law" title="law icons">Law icons created by Freepik - Flaticon</a>
