import styled from "styled-components";
import { PracticeArea } from "../../../client/data";
import { colors } from "../../../lib/colors";

const Container = styled.div({});

const ImageWrapper = styled.div({
  position: "relative",
  width: "350px",
  height: "250px",
  borderRadius: "20px",
});

const ImageText = styled.p({
  textTransform: "capitalize",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 99,
  fontSize: "1.5rem",
  margin: 0,
  color: colors.WHITE,
});

const ColorOverlay = styled.div({
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundColor: "black",
  opacity: "0.4",
  borderRadius: "20px",
});
const Image = styled.img({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "20px",
});

const Name = styled.h1({
  margin: 0,
  marginTop: "10px",
  fontSize: "1.2rem",
  fontWeight: "bold",
});
const Summary = styled.p({
  margin: 0,
  marginTop: "0px",
  fontSize: "1rem",
  fontWeight: "normal",
  maxWidth: "350px",
});

type PracticeCardProps = {
  practiceArea: PracticeArea;
};
export default function PracticeCard(props: PracticeCardProps): JSX.Element {
  const { practiceArea } = props;
  return (
    <Container>
      <ImageWrapper>
        <ImageText>{practiceArea.title}</ImageText>
        <ColorOverlay />
        <Image src={practiceArea.image} alt="house" loading="lazy" />
      </ImageWrapper>
      {/* <Name>{practiceArea.title}</Name>
      <Summary>{practiceArea.summary}</Summary> */}
    </Container>
  );
}
