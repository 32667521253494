import styled from "styled-components";
import { colors } from "../../lib/colors";
import { MOBILE_BREAKPOINT } from "../../lib/breakpoints";
import { darkMode } from "../../lib/colors";

const Section = styled.div({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: colors.BASE,
  position: "relative",
  [MOBILE_BREAKPOINT]: {},
});

const Title = styled.h1({
  fontSize: "3rem",
  fontWeight: "normal",
  width: "100%",
  marginBottom: 0,
  color: "white",
  [MOBILE_BREAKPOINT]: {
    textAlign: "center",
    marginBottom: "50px",
  },
});

const TwoPanel = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  columnGap: "50px",
  width: "100%",
  marginTop: "0",
  height: "100%",
});

const Footer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "60%",
  backgroundColor: colors.BASE,
  height: "100%",
});

const Box = styled.div({});
const FooterHeader = styled.h1({
  fontSize: "1.2rem",
  fontWeight: "bold",
  margin: 0,
  color: "white",
  [MOBILE_BREAKPOINT]: {
    fontSize: "1rem",
  },
});

const FooterText = styled.p({
  fontSize: "1.5rem",
  color: "white",
  margin: 0,
  [MOBILE_BREAKPOINT]: {
    fontSize: "1.5rem",
  },
});

const AddressText = styled.p({
  fontSize: "1.5rem",
  color: "white",
  margin: 0,
  opacity: "66%",
  [MOBILE_BREAKPOINT]: {
    fontSize: "1.5rem",
  },
});

const Divider = styled.hr({
  height: "1px",
  width: "100%",
  textAlign: "center",
  color: darkMode.FOUR,
  marginTop: "auto",
  alignSelf: "flex-end",
});

const CopyRightSignature = styled.p({
  color: "white",
  textAlign: "center",
  [MOBILE_BREAKPOINT]: {
    textAlign: "left",
  },
});

const MapIframe = styled.iframe({
  border: `20px solid ${darkMode.THREE}`,
  display: "none",
  [MOBILE_BREAKPOINT]: {
    display: "block",
  },
});

export default function ContactPage(): JSX.Element {
  return (
    <>
      <Section id="contact-page">
        <Footer>
          <Title>Contact Us</Title>

          <TwoPanel>
            <MapIframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2876.3239825408477!2d-79.37247040220292!3d43.86983858405619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d36f7420c25d%3A0xa0967b1bb1009e78!2sWijesundera%20Associates!5e0!3m2!1sen!2sca!4v1684802310918!5m2!1sen!2sca"
              width="500"
              height="400"
              allowFullScreen={false}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="wijesundera-associates-map"
            ></MapIframe>
            <Box>
              <FooterHeader>WIJESUNDERA ASSOCIATES</FooterHeader>
              <AddressText>Suite 311</AddressText>
              <AddressText>200 Cachet Wood Ct</AddressText>
              <AddressText>Markham, Ontario</AddressText>
              <AddressText>L3R 0K8</AddressText>
              <FooterText>lawyers@wijelaw.com</FooterText>
              <FooterText>905-415-9600</FooterText>
            </Box>
          </TwoPanel>
        </Footer>
        <div style={{ width: "60%", position: "absolute", bottom: 0 }}>
          <Divider />
          <CopyRightSignature>
            © 2023 Wijesundera Associates. All rights reserved.
          </CopyRightSignature>
        </div>
      </Section>
    </>
  );
}
