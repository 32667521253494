export const TEXT = {
  OUR_FIRM: `
    At Wijesundera Associates, we are dedicated to superior results and exemplary client service. \
    Our commitment is to offer comprehensive solutions \
    at reasonable rates. We know our clients do not appreciate \
    wasted time, vague answers nor elaborate schemes. We strive \
    to provide clients with straightforward answers and practical \
    solutions to even the most complex problems. \
    Our initial consultations are offered free or at a reduced rate. `,
};
