import { AboutPage } from "../AboutPage";
import { LandingPage } from "../LandingPage";
import { NavBar } from "../NavBar";
import { TeamPage } from "../TeamPage";
import { AreasPage } from "../AreasPage";
import "./App.css";
import ReviewsPage from "../ReviewsPage";
import ContactPage from "../ContactPage";

export default function App() {
  return (
    <>
      <NavBar />
      <LandingPage />
      <AboutPage />
      <TeamPage />
      <AreasPage />
      <ReviewsPage />
      <ContactPage />
    </>
  );
}
