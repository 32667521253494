import styled from "styled-components";
import { Review } from "../../../client/data";
import { MOBILE_BREAKPOINT } from "../../../lib/breakpoints";

const BaseCard = styled.div({
  width: "80%",
  padding: "25px 15px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  [MOBILE_BREAKPOINT]: {
    width: "50%",
    maxWidth: "800px",
  },
});

const StarsContainer = styled.div({});

const Star = styled.img({
  width: "15px",
  margin: "2px",
});

const Description = styled.p({
  margin: "10px 0 0 0",
  width: "80%",
  fontSize: "1rem",
  [MOBILE_BREAKPOINT]: {
    fontSize: "2rem",
  },
});

const Author = styled.p({
  margin: "10px 0 0 0",
  fontSize: "0.8rem",
  fontWeight: "bold",
  [MOBILE_BREAKPOINT]: {
    fontSize: "1rem",
  },
});

const Source = styled.p({
  margin: "4px 0 0 0",
  fontSize: "0.8rem",
  [MOBILE_BREAKPOINT]: {
    fontSize: "1rem",
  },
});

type ReviewCardProps = {
  review: Review;
};

export default function ReviewCard(props: ReviewCardProps): JSX.Element {
  const { review } = props;
  return (
    <BaseCard>
      <StarsContainer>
        {Array.from({ length: 5 }, (_, index) => (
          <Star src="/star.png" alt="stars" key={index} />
        ))}
      </StarsContainer>
      <Description>{`"${review.description}"`}</Description>
      <Author>{review.author}</Author>
      <Source>{review.source}</Source>
    </BaseCard>
  );
}

// todo <a href="https://www.flaticon.com/free-icons/star" title="star icons">Star icons created by Pixel perfect - Flaticon</a>
