import styled from "styled-components";
import { colors } from "../../lib/colors";

const Title = styled("h1")({
  fontSize: "2.5rem",
  fontWeight: 400,
  margin: "0",
  "@media(min-width: 788px)": {
    fontSize: "4rem",
  },
});

const SubTitle = styled("h2")({
  fontSize: "1.25rem",
  fontWeight: 400,
  margin: "0",
  "@media(min-width: 788px)": {
    fontSize: "2.25rem",
  },
});

const SubTitle2 = styled("h3")({
  fontSize: "1.75rem",
  fontWeight: 400,
  margin: "0",
  "@media(min-width: 788px)": {
    fontSize: "3rem",
  },
});

const Section = styled("div")({
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: colors.BASE,
  color: "#ffffff",
  maxWidth: "100%",
});

const GetStartedButton = styled("button")({
  width: "300px",
  height: "50px",
  backgroundColor: "transparent",
  color: "white",
  marginTop: "20px",
  borderColor: "white",
  borderWidth: "2px",
  borderStyle: "solid",
  textDecoration: "none",
  ":hover": {
    cursor: "pointer",
  },
});

const Container = styled.div({
  marginLeft: "10%",
});

export function LandingPage(): JSX.Element {
  const handleClick = () => {
    const section = document.querySelector("#about-page");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <Section>
      <Container>
        <Title>WIJESUNDERA ASSOCIATES</Title>
        <SubTitle>PROFESSIONAL CORPORATION</SubTitle>
        <SubTitle2>Barristers, Solicitors & Notaries Public</SubTitle2>
        <GetStartedButton onClick={handleClick}>Get Started</GetStartedButton>
      </Container>
    </Section>
  );
}
