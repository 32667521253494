import styled from "styled-components";
import PracticeCard from "./PracticeCard";
import { practiceAreas } from "../../client/data";
import { colors } from "../../lib/colors";
import { MOBILE_BREAKPOINT } from "../../lib/breakpoints";
const Section = styled.div({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "100%",
});

const Grid = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr",
  rowGap: "20px",
  columnGap: "16px",
  [MOBILE_BREAKPOINT]: {
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr",
  },
});

const TitleWrapper = styled.div({
  width: "350px",
});
const Title = styled.h1({
  fontSize: "3rem",
  fontWeight: "normal",
  width: "100%",
  marginBottom: 0,
});

const PracticeCardContainer = styled.div({
  display: "flex",
  justifyContent: "center",
});

const ServeBox = styled.div({
  marginTop: "10%",
  minHeight: "22vh",
  display: "flex",
  flexDirection: "column",
  placeItems: "center",
  backgroundColor: colors.BASE,
  paddingBottom: "20px",
});

const ServeTitle = styled.h1({
  fontSize: "3rem",
  fontWeight: "normal",
  color: colors.WHITE,
});

const Divider = styled.hr({
  height: "1px",
  width: "200px",
  textAlign: "center",
  color: colors.WHITE,
});

const Areas = styled.p({
  margin: "20px 0 20px 0",
  width: "50%",
  textAlign: "center",
  color: colors.WHITE,
  fontSize: "1rem",
  [MOBILE_BREAKPOINT]: {
    fontSize: "1.5rem",
    width: "35%",
  },
});

const LanguagesTitle = styled.h1({
  fontSize: "1.5rem",
  fontWeight: "bold",
  color: colors.WHITE,
  textAlign: "center",
});

const Languages = styled.p({
  margin: "10px 0 20px 0",
  width: "30%",
  textAlign: "center",
  color: colors.WHITE,
  fontSize: "1rem",
  [MOBILE_BREAKPOINT]: {
    fontSize: "1.5rem",
  },
});

const SubTitle = styled.h2({
  fontSize: "1.25rem",
  margin: 0,
  fontWeight: "normal",
  marginBottom: 0,
});

export function AreasPage(): JSX.Element {
  return (
    <>
      <Section>
        <Grid>
          <TitleWrapper>
            <Title>Areas of Practice</Title>
            <SubTitle>
              As a full service law firm, we provide the following services:
            </SubTitle>
          </TitleWrapper>
          {practiceAreas.map((practiceArea, index) => (
            <PracticeCardContainer key={index}>
              <PracticeCard practiceArea={practiceArea} />
            </PracticeCardContainer>
          ))}
        </Grid>
      </Section>
      <ServeBox>
        <ServeTitle>Areas we serve</ServeTitle>
        <Divider />
        <Areas>
          Toronto, Scarborough , North York, Thornhill, Markham, Mississauga,
          Ajax, Aurora, Don Mills, Pickering, Whitby, Oshawa, Vaughan,
          Woodbridge, East York, Etobicoke, St. Catherines, Barrie, Newmarket
          and Milton
        </Areas>
        <Divider />
        <LanguagesTitle>
          We provide serivces in the following languages:
        </LanguagesTitle>
        <Languages>
          English, Mandarin, Cantonese, Punjabi, Tamil, Marathi, Hindi, Sinhala
        </Languages>
      </ServeBox>
    </>
  );
}
