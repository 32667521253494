import React from "react";
import { NavButton } from "./NavButton";
import styled from "styled-components";
import { colors } from "../../lib/colors";
import { MOBILE_BREAKPOINT } from "../../lib/breakpoints";

type NavLogoProps = React.HTMLProps<HTMLAnchorElement>;
const NavLogo = styled.a((props: NavLogoProps) => ({
  fontSize: "3rem",
  margin: "5px",
  marginLeft: "10%",
  textDecoration: "none",
  color: colors.WHITE,
  [MOBILE_BREAKPOINT]: {
    marginLeft: "40px",
  },
}));

const BaseBar = styled.div({
  width: "100%",
  maxWidth: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  position: "absolute",
  justifyContent: "space-between",
});

const NavButtonsContainer = styled.div({
  marginRight: "10%",
  [MOBILE_BREAKPOINT]: {
    marginRight: 0,
  },
});

export function NavBar(): JSX.Element {
  return (
    <BaseBar>
      <NavLogo href="/">WA</NavLogo>
      <NavButtonsContainer>
        <NavButton label={"OUR TEAM"} link={"#team-page"} />
        <NavButton label={"CONTACT"} link={"#contact-page"} />
      </NavButtonsContainer>
    </BaseBar>
  );
}
