// TODO attribution
// Photo by <a href="https://unsplash.com/@liujs?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Johnson Johnson</a> on <a href="https://unsplash.com/images/things/house?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>

export type Review = {
  description: string;
  author: string;
  source: string;
};

export const reviews: Array<Review> = [
  {
    description:
      "“I have been dealing with Wijesundera Associates for a long time now. Neil was very helpful with the closing of my house and when I bought a condo too! I was very comfortable working with Janaki as well. She is very professional, polite and always responds to e-mails or phone calls in a timely manner. I was happy with their services.”",
    author: "Shalindri Nanakara",
    source: "Google Reviews",
  },
  {
    description:
      "Janaki represented me in my divorce proceedings. I appreciate her for her professionalism and competence in handling my case. she was sincere and understanding of my situation. I wanted to give up many time but she advised me of my legal rights and encouraged me to fight for my rights and was able to get me a good settlement. I would recommend her to anyone who is seeking legal advise in family matters.",
    author: "Thilini G",
    source: "Google Reviews",
  },
  {
    description:
      "Neil is my new house lawyer. Neil and his assistant Tina are very kind and knowledgeable. Recently I had a difficult situation for them and I feel so sorry to give them hard time to deal with my new house closing. They’re working very hard for my case and helped me a lot in different ways. Finally, everything set down with a satisfied result. I highly recommend them for anyone who needs legal help. I would like to give them 5+ stars.",
    author: "Yingli Wei",
    source: "Google Reviews",
  },
];

export type PracticeArea = {
  title: string;
  summary: string;
  description: string;
  image: string;
};

export const practiceAreas: Array<PracticeArea> = [
  {
    title: "Real Estate",
    description: `Buying or selling a home can be the largest transaction that many people ever enter into. We understand the emotion and stress that can be involved. We will consult with you throughout the process, and assist you by reviewing the terms of your offer and drafting conditions, representations and warranties where appropriate to best protect your interests. We will help to get you through the transaction and into your new home as smoothly as possible.\n \nWe are experienced in acquisition, disposition and financing of both residential and commercial properties. We act as approved counsel to several of Canada's major banks and lending institutions in a variety of secured lending transactions and assist clients in sourcing and negotiating appropriate financing terms. We also assist our clients arrange financing from banks, trust companies or private lenders, if required. Please contact our real estate team for a free consultation.`,
    summary: "Acquisition, disposition and financing",
    image: "/johnson-johnson-U6Q6zVDgmSs-unsplash.jpg",
  },
  {
    title: "Family Law",
    description: `Our firm represents clients in a wide range of family law matters including:

          - Divorce (contested and uncontested)
          - Separation Agreements
          - Marriage Contracts
          - Cohabitation Agreements
          - Custody and Access
          - Child Support and Variations
          - Spousal Support and Variations
          - Division of Family Property
          - Ordering Freezing Assets
          - Assist in return of abducted children
          - Constructive trust and equitable claims
          - Restraining Orders
          - Opinion on foreign divorce judgements
          - Children's Aid Society Matters
      
      We will conduct a thorough review of your case, provide honest answers to your questions, and explain what we can do to help you. Please reach us by telephone at (905) 415-9600 or via email to discuss your case.`,
    summary: "Family law matters including divorce and separation agreements.",
    image: "/hoi-an-photographer-AILAeiIBrBg-unsplash.jpg",
  },
  {
    title: "Corporate Commercial",
    description: `We provide advice and assistance in the following:

        - Selecting the structure of the corporate entity
        - Creating corporations, subsidiaries, branches, representative offices of foreign corporations, partnerships and sole proprietorships
        - Drafting organizational documentation
        - Leases, franchise agreements, partnership agreements and shareholder agreements
        - Corporate finance
        - Licenses, permits, registration with governmental organizations
        - Purchase and sale of franchises and businesses
    `,
    summary:
      "Creating corporations, organizational documentation, agreements and more",
    image: "/chris-barbalis-IQMG9KbT4yE-unsplash.jpg",
  },
  {
    title: "Wills and Estate",
    description: `The services provided by our Estate Planning Department include:

        Drafting of Last Wills
        Establishment of financial trusts
        Administration of trusts and estates
        Preparing Powers of Attorney
        Court applications for the appointment of estate administrators.
    
    We provide comprehensive support in estate administration, including representing estate trustees or beneficiaries in non-contentious and contentious proceedings.
      `,
    summary:
      "Drafting of last wills, financial trusts, power of attorneys and more",
    image: "/alvaro-serrano-hjwKMkehBco-unsplash.jpg",
  },
  {
    title: "Civil Litigation",
    description: `Our litigation lawyers have extensive knowledge and experience dealing with complex litigation matters before courts, arbitrators, administrative tribunals and regulatory authorities. As experienced negotiators, we will try to resolve your matter within the shortest time frame and in the most cost effective manner. We will aggressively fight for your rights at trial or appeal, if necessary.

      Employment Law
          Wrongful Dismissal
          Constructive Dismissal
          Employment Contracts & Severance Packages
          Disability benefits
          Human Rights & Discrimination
      Breach of Contract
      Negligence
      Misrepresentation
      Creditors Remedies
      Corporate/Shareholder Disputes
      Mortgage Remedies and Enforcement
      Injunctions
      Construction
      Banking Litigation
      Money claims
  
      `,
    summary:
      "Including employment law, breach of contract, negligence and more",
    image: "/sebastian-pichler-bAQH53VquTc-unsplash.jpg",
  },
  {
    title: "Criminal Law",
    description: `Clients that retain Wijesundera Associates are assured top notch, hands on, legal representation. Our lawyers will personally be there for you on every important step along the way. We offer a free consultation on each case.

      Drug offences
      Impaired Driving
      Dangerous Driving
      Weapons and Firearms
      Fraud
      Theft
      Assault and Battery
      Domestic Violence
      Sexual Assault
      Murder
      Tax Evasion
      Appeals
  
      `,
    summary: "Acquisition, disposition and financing",
    image: "/tingey-injury-law-firm-DZpc4UY8ZtY-unsplash.jpg",
  },
  {
    title: "Immigration Law",
    description: `Areas of expertise:

      Family Sponsorship;
      Skilled Workers;
      Work Visas;
      Labour Market Opinions;
      Student Visas;
      Temporary Resident Permits;
      Provincial Nominee Programs;
      Business and Investor visas;
      Live-in caregivers;
      Renewal of permanent resident cards; and
      Canadian Citizenship.
  
  Please consult our immigration lawyers at (905) 415-9600 or via email at lawyers@sympatico.ca 
      `,
    summary: "Acquisition, disposition and financing",
    image: "/mwangi-gatheca-qlKaN7eqay8-unsplash.jpg",
  },
  {
    title: "Personal Injury",
    description: `Areas of experties:

      Motor Vehicle Accident
      Slip and Fall Accident
      Pedestrian Accident
      Long Term Disability Claims
      Brain Injury/Paralysis/Death
      Insurance Benefits
  
  We work in association with one of the top five law firms in Toronto. We believe only a top law firm can obtain the highest cash settlement for our clients.
  
  If you have signed a retainer with a lawyer, but unhappy with how your lawyer is handling your case, please call us. We will arrange a free second opinion for you with a top notch lawyer so that you can make an informed choice. There is no cost to talk to us about your case. We offer a free initial consultation, and you will not have to pay any fees up front. Our fees come as a percentage of whatever compensation we obtain in your case.
  
  Please contact us for your free consultation. 
      `,
    summary: "Acquisition, disposition and financing",
    image: "/lucian-alexe-p3Ip8U0eNNM-unsplash.jpg",
  },
];
