import { MOBILE_BREAKPOINT } from "../../lib/breakpoints";
import { TeamCard } from "./TeamCard";
import styled from "styled-components";
export type TeamMember = {
  img: string;
  description: string;
  name: string;
  title: string;
  email: string;
  phone: string;
};

const teamMembers: Array<TeamMember> = [
  {
    img: "/neil.jpg",
    description: "Neil Wijesundera",
    name: "Neil (Kumaru) Wijesundera, LLM",
    title: "Barrister, Solicitor & Notary Public\n Solicitor (England & Wales)",
    email: "neil@wijelaw.com",
    phone: "905-415-9600",
  },
  {
    img: "/janaki.jpg",
    description: "Janaki Wijesundera",
    name: "Janaki Wijesundera, LLM",
    title: "Barrister  & Solicitor\n Solicitor (England & Wales)",
    email: "lawyers@wijelaw.com",
    phone: "905-415-9600",
  },
  {
    img: "/martha.jpg",
    description: "Martha De Souza",
    name: "Martha De Souza, LLB",
    title: "Barrister & Solicitor",
    email: "martha@marthadsouza.com",
    phone: "905-604-3615",
  },
  {
    img: "/tina.jpg",
    description: "Tina Cao",
    name: "Tina Cao",
    title: "Certified Law Clerk (Hons)\n Senior Real Estate Law Clerk",
    email: "tina@wijelaw.com",
    phone: "905-415-9600",
  },
  {
    img: "/david.jpg",
    description: "David Jayaweera",
    name: "David Jayaweera",
    title: "Attorney-at-Law (Sri Lanka)",
    email: "david@wijelaw.com",
    phone: "905-415-9600",
  },
];

const Section = styled.div({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});
const Container = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  rowGap: "20px",
});

const Title = styled.h1({
  fontSize: "3rem",
  fontWeight: "normal",
  marginTop: "20px",
  marginBottom: 0,
  [MOBILE_BREAKPOINT]: {
    margin: 0,
    marginTop: "40px",
  },
});

const SubTitle = styled.h2({
  fontSize: "1.25rem",
  margin: 0,
  fontWeight: "normal",
  marginBottom: "30px",
});

export function TeamPage(): JSX.Element {
  return (
    <Section id="team-page">
      <Title>Our Team</Title>
      <SubTitle>Our lawyers are experts in a range of fields</SubTitle>
      <Container>
        {teamMembers.map((member, index) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TeamCard teamMember={member} key={index} />
          </div>
        ))}
      </Container>
    </Section>
  );
}
