import React from "react";
import styled from "styled-components";
import { colors } from "../../../lib/colors";
import { MOBILE_BREAKPOINT } from "../../../lib/breakpoints";

type ButtonProps = React.HTMLProps<HTMLAnchorElement>;
const Button = styled.a((props: ButtonProps) => ({
  textDecoration: "none",
  color: colors.WHITE,
  margin: "5px",
  fontSize: "1rem",
  [MOBILE_BREAKPOINT]: {
    fontSize: "1.25rem",
    marginRight: "20px",
  },
}));

type NavButtonProps = {
  label: string;
  link: string;
};

export function NavButton(props: NavButtonProps): JSX.Element {
  return <Button href={props.link}>{props.label}</Button>;
}
